
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import CFormInputText from "@/components/form/InputText.vue";
import { useFilter } from "@/libs/utils";

const VEmployeeFilter = defineComponent({
  name: "VEmployeeFilter",
  components: { CFormInputText },
  setup() {
    useHead({ title: "Filtrar usuários | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { hasAnyFilters } = useFilter();

    const filters = reactive({ name: route.query.name || undefined });
    const loading = reactive({ submit: false });

    async function applyFilters() {
      router.replace({ query: { ...route.query, ...filters } });

      loading.submit = true;
      await store.dispatch("searchEmployees", { search: filters, isPrimary: true });
      loading.submit = false;
    }

    function resetFilters() {
      filters.name = undefined;
      applyFilters();
    }

    function handleClose() {
      router.replace({ name: "employee-list", query: route.query });
    }

    if (hasAnyFilters.value) applyFilters();

    return { filters, loading, applyFilters, resetFilters, handleClose };
  },
});

export default VEmployeeFilter;
